<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-divider content-position="center" />
    <div class="my-20">
      请确认要对群组中的手机号用户投放门票，投放后用户可直接获得门票并亮码。
    </div>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="success" @click="handleSave">确认投放</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref, getCurrentInstance, onMounted } from 'vue'
  import { importTicket } from '@/api/exhibitionManage'
  import Bus from '@/utils/bus'
  import { debounce } from '@/utils/debounce'
  const props = defineProps({
    title: {
      type: String,
      default: '编辑',
    },
  })
  const { proxy } = getCurrentInstance()
  const dialogFormVisible = ref(false)
  const state = reactive({
    form: {
      batchNo: '',
      file: null,
    },
  })
  // 编辑更新
  // const putUpdateRemark = async () => {
  //   let data = {
  //     remarkPc: state.form.remarkPc,
  //     ticketCode: state.form.ticketCode,
  //   }
  //   let resp = await updateRemark(data)
  // }
  const emit = defineEmits(['noticeThrowResultRefresh'])
  // 关闭
  const handleClose = () => {
    dialogFormVisible.value = false
  }
  // 打开
  const handleOpen = (row = {}) => {
    state.form = row
    dialogFormVisible.value = true
  }
  // 保存
  const handleSave = debounce(async () => {
    let formData = new FormData()
    formData.append('file', state.form.file)
    try {
      handleClose()
      let resp = await importTicket(formData, state.form.batchNo)
      proxy.$baseMessage(`投放成功`, 'success', 'vab-hey-message-success')
      emit('noticeThrowResultRefresh')
    } catch (err) {
      proxy.$baseMessage(`投放失败`, 'error', 'vab-hey-message-error')
    } finally {
      handleClose()
    }
  })
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
